<template>
  <forms-control
    :class="classes"
    v-bind="$props">
    <!-------------------------- Header--------------------------------->
    <!-- Before -->
    <template v-slot:before>
      <slot name="before"></slot>
    </template>

    <!-- Label -->
    <template v-slot:default>
      <slot></slot>
    </template>

    <!-- After -->
    <template v-slot:after>
      <slot name="after"></slot>
    </template>

    <!-------------------------- Body --------------------------------->

    <!-- Prefix -->
    <template v-slot:prefix>
      <actions-button
        @click.stop="showPopin = !showPopin"
        :appearance="$pepper.Appearance.DEFAULT"
        class="forms-controll__pre -is-interactive forms-phone__select"
        :size="size"
        ref="options"
      >{{ selectLabel }}</actions-button>

      <popins-list-dropdown
        @change="handleCountrySelect"
        class="forms-phone__countries-dropdown"
        :visible="showPopin"
        :options="countries"
        :layout="['field', 'label']"
        :target="$refs.options"
        searchable
      ></popins-list-dropdown>
    </template>

    <!-- Content -->
    <template v-slot:content>
      <!-- Field -->
      <label class="forms-control__container">
        <!--  -->
        <span
          v-if="isColor"
          class="forms-control__field">{{ value }}</span>

        <input
          v-autofocus="autofocus"
          v-bind="inputAttrs"
          v-model="ivalue"
          v-on="listeners"
          />
      </label>
    </template>

    <!-- Suffix -->
    <template v-slot:suffix>
      <slot name="suffix">

        <!-- Icon -->
        <component
          v-if="iconPost && !clearable"
          class="forms-control__post"
          :class="{'-is-interactive': iconPostInteractive}"
          :disabled="iconPostDisabled"
          :is="iconPostInteractive ? 'button' : 'div'"
          v-on="iconPostListerners">
          <ui-icon
            class="forms-control__icon"
            :glyph="iconPost"
          ></ui-icon>
        </component>

        <!-- Clear -->
        <button
          v-if="clearable && value && value.length > 0"
          class="forms-control__post -is-interactive forms-control__clear"
          @click="onClear">
          <ui-icon
            class="forms-control__icon"
            glyph="cross"/>
        </button>
      </slot>

      <!-- Search icon -->
      <ui-icon
        class="forms-control__post forms-control__icon"
        v-if="isSearch"
        glyph="search"
        />

      <!-- isNumber => Plus -->
      <button
        aria-controls="spinner-input"
        class="forms-control__post -is-interactive"
        @click="increase"
        :disabled="iconPostDisabled || maxed || disabled || readOnly"
        v-if="isNumber && !isViewSubtle">

        <ui-icon
          class="forms-control__icon"
          glyph="plus"
        ></ui-icon>
      </button>
    </template>
  </forms-control>
</template>
<script>

import { Appearance, PepperSlot } from '@pepper/utils'
import { Autofocus } from '@pepper/directives-autofocus'

import { FormsControl } from '@pepper/forms-control'
import { FormsLabel } from '@pepper/forms-label'
import { UiIcon } from '@pepper/ui-icon'
import { UiLoader } from '@pepper/ui-loader'
import { ClickOutside } from '@spices/pepper'

export default {
  name: "FormsInput",

  components: {
    FormsLabel,
    UiIcon,
    UiLoader
  },

  directives: {
    Autofocus,
    ClickOutside,
  },

  mixins: [
    PepperSlot
  ],

  extends: FormsControl,

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    countryId: {
      type: String,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    size: {
      type: String,
      default: 'm'
    },

    autocomplete: {
      type: Boolean,
      default: true
    },

    autofocus: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: false
    },

    iconPre: {
      type: String
    },

    iconPreDisabled: {
      type: Boolean,
      default: false
    },

    iconPreInteractive: {
      type: Boolean,
      default: false
    },

    iconPost: {
      type: String
    },

    iconPostDisabled: {
      type: Boolean,
      default: false
    },

    iconPostInteractive: {
      type: Boolean,
      default: false
    },

    inputMode: {
      type: String,
      values: ['text', 'tel', 'url', 'email', 'numeric', 'decimal', 'search'],
      validator: (value) => ['text', 'tel', 'url', 'email', 'numeric', 'decimal', 'search'].includes(value)
    },

    max: {},

    min: {},

    pattern: {
      type: String
    },

    placeholder: {
      type: String
    },

    step: {},

    type: {
      type: String,
      default: 'text',
      values: ['color', 'date', 'datetime-local', 'email', 'file', 'number', 'password', 'search', 'tel', 'text', 'time', 'url'],
      validator: (value) => ['color', 'date', 'datetime-local', 'email', 'file', 'number', 'password', 'search', 'tel', 'text', 'time', 'url'].includes(value)
    },

    value: {}
  },

  data(){
    return {
      ivalue: null,
      showPopin: false
    }
  },

  watch: {
    value(val){
      this.ivalue = val;
    }
  },

  computed: {
    classes(){
      return {
        'forms-phone': true,
        'forms-input': true,

        '-view-none': this.appearance === 'none',

        '-icon-pre': this.iconPre,
        '-icon-pre-disabled': this.iconPreDisabled,
        '-icon-pre-interactive': this.iconPreInteractive,
        '-icon-post': this.iconPost,
        '-icon-post-disabled': this.iconPostDisabled,
        '-icon-post-interactive': this.iconPostInteractive,

        '-is-required': this.required,
        '-is-read-only': this.readOnly,

        '-is-number': this.type === 'number',
        '-is-search': this.isSearch,

        '-has-prefix': this.has('prefix'),
        '-has-suffix': this.has('suffix')
      }
    },

    countries() {
      return this.options.map(opt => {
        return {
          name: opt.name,
          label: `${opt.flag} ${opt.label} (${opt.callingCode})`,
          value: opt.id,
          active: opt.id === this.countryId
        }
      })
    },

    selectedCountry() {
      return this.options.find(opt => opt.id === this.countryId)
    },

    iconPreListeners(){
      return this.iconPreInteractive ? {'click': (event) => this.onIconClick(event, 'pre') } : {};
    },

    iconPostListerners(){
      return this.iconPostInteractive ? {'click': (event) => this.onIconClick(event, 'post') } : {};
    },

    inputAttrs(){
      let ret = Object.assign({}, this.$attrs);

      ret = Object.assign(ret, {
        ariaLive: this.isNumber ? 'assertive' : null,
        autocomplete: this.autocomplete === false ? 'off' : null,
        autofocus: this.autofocus,
        class: "forms-control__field",
        id: this.id,
        inputmode: this.inputMode,
        disabled: this.disabled,
        max: ['number'].includes(this.type) ? this.max : null,
        maxlength: ['text'].includes(this.type) ? this.max : null,
        min: this.min,
        name: this.name,
        pattern: this.pattern,
        placeholder: this.placeholder,
        readonly: this.readOnly,
        ref: 'field',
        required: this.required,
        step: this.step,
        type: 'tel',
        value: this.value
      })

      return ret;
    },

    isColor(){
      return this.type === 'color'
    },

    isNumber(){
      return this.type === 'number'
    },

    isSearch(){
      return this.type === 'search'
    },

    isViewSubtle(){
      return this.appearance === Appearance.SUBTLE
    },

    listeners(){
      let _this = this;
      let ret = Object.assign({}, this.$listeners);
      ret = Object.assign(ret, {
        input(event){ _this.onInput(event) }
      });
      return ret;
    },

    maxed(){
      let max = this.max || Number.MAX_SAFE_INTEGER;
      let value = parseFloat(this.ivalue);
      return value >= max;
    },

    mined(){
      let min = this.min || Number.MIN_SAFE_INTEGER;
      let value = parseFloat(this.ivalue);
      return value <= min;
    },

    selectLabel() {
      return `${this.selectedCountry.flag} ${this.selectedCountry.callingCode}`
    }
  },

  methods: {
    click(){
      try{
        this.$refs.field.click();
      }
      catch(e){
        console.log(e);
      }
    },

    decrease(){
      let min = this.min || Number.MIN_SAFE_INTEGER;
      let step = parseFloat(this.step) || 1;
      let value = parseFloat(this.ivalue);
      value = (value - step) > min ? value - step : min;
      this.$emit('input', value);
    },

    increase(){
      let max = this.max || Number.MAX_SAFE_INTEGER;
      let step = parseFloat(this.step) || 1;
      let value = parseFloat(this.ivalue) || 0;
      // value = (value + step) < max ? value + step : max;
      // console.log(value, step, value + step);
      value = value + step;
      this.$emit('input', value );
    },

    focus(){
      this.$refs.field.focus();
    },

    onClear(event){
      this.ivalue = '';
      this.$refs.field.focus();
      this.$emit('clear', event);
      this.$emit('input', this.ivalue);
    },

    onIconClick(event, position){
      this.$emit('iconClick', event, position);
    },

    onInput(event){
      let ret = this.ivalue
      this.$emit('input', ret);
    },

    handleCountrySelect(countries) {
      let country = countries.find(c => c.active)
      this.$emit('country-changed', country.value)
      this.showPopin = false
    }
  },

  mounted() {
    this.ivalue = this.value;
  }
}
</script>
