<script>
import FormsPhone from '@/components/forms/input-phone.vue'
import FormsPinboard from '@/components/forms/pinboard.vue'
import { DateTime } from 'luxon'

export default {
  name: 'ViewProfile',

  components: {
    FormsPhone,
    FormsPinboard,
  },

  data() {
    return {
      showModal: false,
      phone: '',
      email: '',
      countries: [],
      errors: {},
      country: null,
      loading: false,
      saving: false,
      code: [],
      key: 0,
      codeError: null,
      emailResended: null
    }
  },

  computed: {
    hasEmailNotVerified() {
      return this.email !== '' && !this.$user.user.emailVerified
    },

    hasPhoneNotVerified() {
      return this.phone !== '' && !this.$user.user.phoneVerified
    },

    isValid() {
      return !this.$basil.isNil(this.$user.user.firstname) && !this.$basil.isEmpty(this.$user.user.firstname) &&
        !this.$basil.isNil(this.$user.user.lastname) && !this.$basil.isEmpty(this.$user.user.lastname) &&
        !this.$basil.isNil(this.$user.user.email) && !this.$basil.isEmpty(this.$user.user.email)
    },
  },

  methods: {
    handleCountryChange(id) {
      this.country = id
      let { isoCode2 } = this.countries.find(c => c.id === id)
      this.$user.user.phoneCountryCode = isoCode2
    },

    async init() {
      this.loading = true

      try {
        await this.$user.getUser()
        let list = await this.$user.countries()

        this.countries = list.filter(c => c.callingCode)

        let geoCode = this.$basil.get(this.bootstrap, 'geolocation_country_code')
        let phoneCountry = this.$basil.get(this.$user, 'user.phoneCountryCode', geoCode) || geoCode

        if(!this.$basil.isNil(phoneCountry)) {
          let country = this.countries.find(c => c.isoCode2 === phoneCountry)
          if(!this.$basil.isNil(country)) {
            this.country = country.id
          }
        }
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    async onConfirm() {
      this.errors = {}
      this.saving = true

      try {
        let res = await this.$user.update(this.$user.user)
        this.setLocale(res.lang)
        this.$notification({
          title: this.$t('conn3ct-wallet.user_saved_successfully'),
          type: this.$pepper.Intent.SUCCESS
        })

        this.$user.user = res

        this.phone = this.$basil.get(this.$user, 'user.phoneNumber', '') || ''
        this.email = this.$basil.get(this.$user, 'user.email', '') || ''
      } catch(e) {
        this.$user.user.parsePhone(this.$user.user.phoneNumber)
        e.phone_number = e.phone_number?.map(err => this.$t(`conn3ct-wallet.${err}`)) ?? []
        this.errors = e

        if(e.response?.status === 500) {
          this.$notification({
            message: this.$t('conn3ct-wallet.save_user_error'),
            type: 'error'
          })
        }
        $console.error(e)
      } finally {
        this.saving = false
      }
    },

    async validatePhone() {
      this.saving = true
      this.codeError = false

      try {
        await this.$user.verifyPhone({ code: this.code.join('') })
        await this.$user.getUser()
        this.showModal = false
      } catch(e) {
        this.codeError = true
        this.code = []
        $console.error(e)
      } finally {
        this.saving = false
      }
    },

    resend(entity) {
      if(entity === 'email') {
        this.emailResended = DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)
        return this.$user.resendVerificationEmail()
      }

      return this.$user.resendVerificationPhone()
    },
  },

  created() {
    window.scrollTo(0, 0)
    this.init()
    this.phone = this.$basil.get(this.$user, 'user.phoneNumber', '') || ''
    this.email = this.$basil.get(this.$user, 'user.email', '') || ''
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_dashboard-dashboard' }"
    class="module-user profile"
    :loading="loading"
    :subtitle="$t('conn3ct-wallet.profile_description')"
    :title="$t('conn3ct-wallet.profile_title')"
  >
    <section class="profile__form">
      <forms-input
        v-model="$user.user.firstname"
        :placeholder="$t('conn3ct-wallet.firstname_placeholder')"
        required
      >{{ $t('conn3ct-wallet.firstname_lbl') }}</forms-input>

      <forms-input
        v-model="$user.user.lastname"
        :placeholder="$t('conn3ct-wallet.lastname_placeholder')"
        required
      >{{ $t('conn3ct-wallet.lastname_lbl') }}</forms-input>

      <div>
        <forms-input
          v-model="$user.user.email"
          :disabled="$user.user.registrationIdentifier === 'email'"
          :placeholder="$t('conn3ct-wallet.email_placeholder')"
          type="email"
          :readonly="$user.user.registrationIdentifier === 'email'"
        >{{ $t('conn3ct-wallet.email_lbl') }}</forms-input>
        <span
          v-if="emailResended"
          class="profile__info"
        >{{ $t('conn3ct-wallet.profile_email_resend_at', {
          email_address: $user.user.email,
          ddtime: emailResended
        }) }}</span>

        <span
          v-if="hasEmailNotVerified"
          class="profile__warn"
        >{{ $t('conn3ct-wallet.profile_not_verified_email') }} <a @click="resend('email')">{{ $t('conn3ct-wallet.resend_email_action') }}</a></span>
      </div>

      <forms-select
        v-model="$user.user.lang"
        :options="locales"
        :placeholder="$t('conn3ct-wallet.lang_select_placeholder')"
      >{{ $t('conn3ct-wallet.lang_select_lbl') }}</forms-select>

      <div>
        <forms-phone
          v-if="countries"
          v-model="$user.user.phoneNumber"
          @country-changed="handleCountryChange"
          :country-id="country"
          :disabled="$user.user.registrationIdentifier === 'phone_number'"
          :errors="errors['phone_number']"
          :options="countries"
        >{{ $t('conn3ct-wallet.phone_lbl') }}</forms-phone>

        <span
          v-if="hasPhoneNotVerified"
          class="profile__warn"
        >{{ $t('conn3ct-wallet.profile_not_verified_phone') }} <a @click="showModal = true">{{ $t('conn3ct-wallet.enter_phone_code_action') }}</a></span>
      </div>

      <actions-button
        @click="onConfirm"
        :appearance="$pepper.Appearance.PRIMARY"
        class="profile__action"
        :disabled="!isValid || saving"
        :loading="saving"
      >{{ $t('conn3ct-wallet.save_profile') }}</actions-button>
    </section>

    <popins-modal
      @close="showModal = false"
      :blanketDisabled="false"
      position="middle-center"
      :size="$pepper.Size.XS"
      :visible="showModal"
    >
      <forms-pinboard
        v-model="code"
        @input="key++"
      />

      <span
        v-if="codeError"
        class="profile-modal__warn"
      >{{ $t('conn3ct-wallet.phone_verify_invalid_token') }}</span>

      <template v-slot:footer>
        <div class="profile-modal__footer">
          <actions-button @click="resend('phone')">{{ $t('conn3ct-wallet.resend_phone_action') }}</actions-button>

          <actions-button
            @click="validatePhone"
            :appearance="$pepper.Appearance.PRIMARY"
            :disabled="saving || code.length !== 5"
            :loading="saving"
          >{{ $t('conn3ct-wallet.validate_phone_action') }}</actions-button>
        </div>
      </template>
    </popins-modal>
  </layout-page>
</template>
